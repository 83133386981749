<template>
  <div class="w-full space-y-16">
    <!-- <div class="p-4 rounded-xl tw-flex2 tw-bg-yellow space-y-4">
      <div v-html="md(md_notice)"></div>
    </div> -->

    <!-- <div class="tw-flex2 tw-bg-yellow space-y-4">
      <div>
        <h1>NFT 정보</h1>
        <div>현재수량: 1,000</div>
        <div>남은수량: 100</div>
        <div>비용: 1 KLAY</div>
      </div>
      <div >AAA</div>
    </div> -->

    <!-- <div class="tw-flex2 tw-bg-yellow">
      <div>A</div>
      <div>B</div>
    </div> -->

    <!--
    <div v-if="false" class="tw-flex2 tw-bg-yellow space-y-4">
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div v-html="$store.mdt('#PDAK_MSG_WEBGAME')"></div>
        <div class="space-y-4">
          <div>
            <div
              class="text-sm"
              v-html="$store.mdt('#PDAK_MSG_WEBGAM_KABOOM')"
            ></div>
            <button @click="onGamePlay('kaboom')">
              <div class="flex items-center space-x-2">
                <img
                  class="h-12"
                  src="/img/icons/kaboom_logo.png"
                  alt="kaboom logo"
                />
                <div class="font-bold text-2xl">▷</div>
              </div>
            </button>
          </div>

          <div>
            <div
              class="text-sm"
              v-html="$store.mdt('#PDAK_MSG_WEBGAM_UNITY')"
            ></div>
            <div>
              <button class="flex space-x-2" @click="onGamePlay('unity')">
                <div class="flex items-center space-x-2">
                  <img
                    class="h-10"
                    src="/img/icons/unity_logo.png"
                    alt="unity logo"
                  />
                  <div class="font-bold text-2xl">▷</div>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div v-if="game_show">
          <KaboomDialog
            :frameWidth="frameWidth"
            :frameHeight="frameHeight"
            :src="game_src"
            @onClose="game_show = false"
          />
        </div>
      </div>
    </div>
    -->

    <div class="tw-flex2 tw-bg-yellow space-y-4">
      <div v-html="$store.mdt('#PDAK_MSG_1')"></div>
      <div class="md:ml-4">
        <button
          @click="onFarmDoor()"
          class="w-32 p-4 space-y-2 rounded-lg bg-black text-white"
        >
          <div v-html="$store.mdt('#PDAK_ENTER')"></div>
        </button>
      </div>
    </div>

    <!--
    <div class="tw-flex2 tw-bg-yellow">
      <div v-html="$store.mdt('#PDAK_MSG_2')"></div>
      <div class="rounded-lg overflow-hidden flex items-center">
        <img class="w-full" src="/img/pdak/potdak_egg_reduce-2.png" />
      </div>
    </div>

    <div class="tw-flex2 tw-bg-yellow">
      <div v-html="$store.mdt('#PDAK_MSG_3')"></div>
      <div class="flex items-center rounded-lg overflow-hidden">
        <img
          class="w-full rounded-lg"
          src="/img/pdak/potdak_upgrade_to_3d.png"
        />
      </div>
    </div>

    <div class="tw-flex2 tw-bg-yellow">
      <div v-html="$store.mdt('#PDAK_MSG_4')"></div>
      <div class="rounded-lg overflow-hidden flex items-center">
        <img
          class="rounded-lg w-full"
          src="/img/pdak/potdak_menu_desc_change-2.png"
        />
      </div>
    </div>
    -->
  </div>
</template>

<script>
// import Contract from "@/abi-cypress-pd-tpd1/PotDak.json";
// import PDDatas from "@/potdak/datas.js";
// import KaboomDialog from "@/components/KaboomDialog.vue";

export default {
  // components: { KaboomDialog },
  data() {
    return {
      bg_abc: "bg-red-300",
      md_notice: `
### 공지

<sub>2022-04-22</sub>  
스마트컨트랙트에 교체 작업이 완료되었습니다.  
기존의 문제있는 NFT 는 정리해주시기 바랍니다.    
<br/>
[민팅 버튼]을 작업중입니다.  
이후에는 버튼을 눌러 민팅 가능해집니다.
      `.trim(),
      //
      game_show: false,
      game_src: "",
      frameWidth: 320,
      frameHeight: 240,
      //
      // addr: "",
      // contract: null,
      // framEnterError: "",
    };
  },
  mounted() {
    console.log("src", this.game_src);
    this.$store.commitRoot("set_ui", this.$route.fullPath);
  },
  methods: {
    async onFarmDoor() {
      const func_success = () => {
        this.$router.push("/pdak/farm");
      };

      console.log("onFarmDoor");
      if (this.$store.state.lv2_is_login) {
        func_success();
      } else {
        this.$store.state.lv2_login_after = (ok) => {
          if (!ok) return;
          func_success();
        };
        this.$store.state.lv2_show_wallet_login = true;
      }
    },
    onGamePlay(engine) {
      const w = window.innerWidth; //this.mybg.clientWidth;
      const h = window.innerHeight; // this.mybg.clientHeight;
      console.log("onGamePlay", w, h);

      let w2 = w * 0.8;
      let h2 = h * 0.8;
      let ratio = w2 / h2;
      if (ratio > 1.777) {
        w2 = (h2 * 3) / 2;
      } else {
        h2 = (w2 / 3) * 2;
      }

      this.frameWidth = Math.floor(w2);
      this.frameHeight = Math.floor(h2);
      console.log("wh #2", this.frameWidth, this.frameHeight);

      if (engine == "kaboom") {
        // this.game_src = "/test1/game.html";
        this.game_src = "/run-v2/game.html";
        // this.game_src = "https://plantosyrun.gunillee.repl.co/v2/game.html";
        this.game_show = true;
      } else if (engine == "unity") {
        this.game_src = "/test2/game.html";
        this.game_show = true;
      }
    },
  },
};
</script>

<style scoped>
.tw-flex2 {
  @apply p-4 rounded-xl flex-none md:flex justify-center items-center;
}
.tw-bg-yellow {
  @apply bg-yellow-300 dark:bg-yellow-800 dark:text-white;
}
</style>
